.print_header{
padding: 12px 10px ;
}
.print_back{
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 13px;
    cursor: pointer;
}
.print_logo{
    width: 50px;
    height: 50px;
}

.print_login{
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 13px;
    cursor:pointer;
}

.cartIcon{
    position: relative;
}
.cartSvg{
    font-size: 37px;
    cursor:pointer;
}
.totalQuantity{
    width: 30px;
    height: 30px;
    font-size: 14px;
    top: -12px;
    right: -12px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    background: #223d8f;
    font-style: normal;
    color: #fff;
    font-weight: bold;
}
.headerBeck{
    font-size: 20px;
    cursor: pointer;
}