.book-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.cover-left, .cover-right, .spine {
    height: 100%;
}

.cover-left, .cover-right {
    width: 50%;
    background-color: rgb(74, 56, 47);
    background-image: url('https://cdn.dragdrop.design/assets/material/vintage_leather_aviator_tile_1600.jpg');
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1.5901px 1.19258px, rgba(0, 0, 0, 0.08) -1.32509px 3.18021px 2.38515px;
}

.spine {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
    width: 5.3%;
    background-color: rgb(74, 56, 47);
    background-image: url('https://cdn.dragdrop.design/assets/material/vintage_leather_aviator_tile_1600.jpg');
    background-size: cover;
    box-shadow: rgba(255, 255, 255, 0.12) 7.52165px 0px 2.50722px inset, rgba(255, 255, 255, 0.12) -7.52165px 0px 2.50722px inset, rgba(0, 0, 0, 0.05) 1.67148px 0px 1.88041px inset, rgba(0, 0, 0, 0.05) -1.67148px 0px 1.88041px inset, rgba(255, 255, 255, 0.28) 0px 2.50722px 3.76082px inset, rgba(0, 0, 0, 0.2) 0px -2.50722px 1.88041px inset;
}

.spine_black_shadow {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5.56536px 4.77031px, rgba(0, 0, 0, 0.12) 0px 9.54062px 14.3109px, rgba(0, 0, 0, 0.45) -12px 0px 12px -6px, rgba(0, 0, 0, 0.3) 9.6px 0px 9.6px -4.8px;
}

.spine_white_shadow {
    height: 100%;
    width: 100%;
    background-color: rgb(255 255 255);
    opacity: 0.08;
    mix-blend-mode: soft-light;
}
