.selectSizeContainer {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
.textDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 10px;
}
.text1 {
  width: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.text2 {
  width: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.text3 {
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.sizeContainer {
  height: 350px;
  overflow-y: auto;
  padding: 10px;
}

.size {
  width: 100%;
  height: 38px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  margin: 4px 20px;
}

.currentSize {
  width: 100%;
}

.finish {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.imageType {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.finish p {
  width: 92%;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 20px;
  font-weight: 600;
}

.quantity {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.count {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.quantity p {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.plus-minus {
  background: #223d8f;
  color: white;
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 5px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 0px;
  cursor: pointer;
  user-select: none;
}
.currentNumber {
  width: 60px;
  height: 38px;
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 5px;
}
.add-to-cart {
  width: 82%;
  height: 40px;
  border-radius: 0;
  color: white;
  background-color: rgb(34 61 143);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  margin: 4px 20px;
}

.add-to-cart svg {
  display: none;

}
.addToCartOK {
  width: 82%;
  height: 40px;
  border-radius: 0;
  color: #223d8f;
  background-color: white;
  border: 3px solid #223d8f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  margin: 4px 10px;
}
.addToCartOK svg{
  margin-right: 5px;
  color: green;
  font-size: 20px;
}
.view {
  width: 320px;
  height: 40px;
  border-radius: 0;
  border: 2px solid black;
  margin-top: 20px;
}
.selected {
  background-color: #223d8f;
  color: white;
}

.ShowMore {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 0;
  color: white;
  background-color: rgb(34 61 143);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  margin: 4px 20px;
}
.ShowMore:hover {
  background-color: rgb(61, 98, 208);
}
@media only screen and (max-width:992px){

  .sizeContainer {
    height: 200px;
  }

}