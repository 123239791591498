
.input_price {
  margin-bottom: 5px;
  color: #223d8f;
  width: 180px;
  margin-right: 10px;
  text-align: center;
  border: 2px solid transparent;
  border: 2px solid #dadada;
}

.input_price:hover,
.input_price:focus,
.input_price:active {
  border: 2px solid #223d8f;
}

.btn_price {
  background: #4CAF50;
  border: 1px solid #4CAF50;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
  min-width: 110px;
 
}

.remove_category_link:hover{
  color:red;
  text-decoration: underline;
}

.remove_category_link{
  color:red;
  text-align: start;
  text-decoration: none;
  margin-top: 4px;
}

.for-ref-left{
  height: 500px;
  width: 200px;
  position: absolute;
}

.for-ref-right{
  height: 500px;
  width: 200px;
  position: absolute;
  right: 20px;
}
.for-ref-card{
  height: 500px;
  width:350px;
  position: absolute;
  right: 35%;
}
.btn_price:hover,
.btn_price:focus,
.btn_price:active {
  background-color: #218838 !important;
  border: 2px solid #1e7e34 !important;
  color: #fff !important;
}

.btn_save {
  width: 10%;
  min-width: 120px;
}

.ant-spin-nested-loading {
  overflow-x: hidden;
  width: inherit;
}

.default_text{
  font-size: 16px;
  font-weight: 500;
  padding: 4px 0px;
}
.default_checkbox .ant-checkbox-inner{
  width: 28px;
  height: 28px;
}

.default_checkbox .ant-checkbox-inner::after {
  top: 46%;
  left: 32%;
  width: 7px;
  height: 12px;
}
.divSave{
  text-align: right;
  margin-right: 14px;
  margin-top: 10px;
}

.peicTable  .ant-layout-content{
  padding: 5px !important;
}
.sellingPrice{
  width: 150px;
}
.labDashbordLayout{
  padding: 0 24px 24px;
}
.labDashbordContent{
  padding: 0px;
  margin: 0px;
  background: colorBgContainer;
}

.costGreen{
  color: green;
}
.costRed{
  color: red;
}
.textInput{
  width: 152px;
  margin-right: 10px;
}


@media  only screen and  (max-width: 1100px) {
  .divChecked {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

}@media  only screen and  (max-width: 577px) {
  .sellingPrice {
    width: 100px !important;
  }
}
@media  only screen and  (max-width: 520px) {
  .btn_price{
    min-width: 100px !important;
  }
  .default_text{
    font-size: 12px;
  }
  .default_checkbox .ant-checkbox-inner{
    width: 20px;
    height: 20px;
  }
}
@media  only screen and  (max-width: 420px) {
  .textInput{
    width: 170px;
    max-width: 100%;
    font-size: 12px;
  }

  .labDashbordLayout{
    padding: 0 10px 10px !important;
  }
 .priceListTable .ant-table{
  font-size: 11px !important;
 }
 .priceAddTable .ant-table{
  font-size: 11px !important;
 }
 .priceAddTable .ant-table-cell{
  padding: 0px !important;
 }
 .priceListTable .my_ant_icons_table{
  font-size: 18px !important;
 }
 .studioModeContainerc .ant-btn{
  font-size: 10px !important;
  height: 28px !important;
  padding: 1px 8px !important;
 }
}

