.album-layout {
    height: 100vh;
}

.header-content {
    display: flex;
    justify-content: space-between;
}

.album-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.album-page {
    width: 300px;
    height: 400px;
    margin: 0 10px;
    border: 1px solid #000;
}
.album_content_size {
    width: 950px;
    height: 500px;
    position: relative;
}

.footer-content {
    display: flex;
    justify-content: space-between;
}

