/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gabriela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url("https://cdn.syncfusion.com/ej2/bootstrap5.css");
@import url('https://fonts.googleapis.com/css2?family=Gabriela&display=swap');
@import url('https://fonts.cdnfonts.com/css/gabriola');


@font-face {
    font-family: 'Gabriola', sans-serif;
}


@font-face {
    font-family: 'BernhardModern';
    src: url('../src/fonts/BernhardMordern.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="BernhardModern"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="BernhardModern"]::before
{
    font-family: "BernhardModern", cursive;;
    content: "BernhardModern" !important;
}


.ql-font-BernhardModern {
    font-family: "BernhardModern";
}
@font-face {
    font-family: 'Gabriela';
    src: url('../src/fonts/Gabriela.woff2') format('woff2');

}
.ql-picker.ql-font .ql-picker-label[data-value="Gabriela"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Gabriela"]::before
{
    font-family: "Gabriela", cursive;;
    content: "Gabriela" !important;
}


.ql-font-BernhardModern {
    font-family: "BernhardModern";
}

@font-face {
    font-family: 'BickhamScriptPro';
    src: url('../src/fonts/BickhamScriptProRegular.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="BickhamScriptPro"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="BickhamScriptPro"]::before
{
    font-family: "BickhamScriptPro", cursive;;
    content: "BickhamScriptPro" !important;
}


.ql-font-BickhamScriptPro {
    font-family: "BickhamScriptPro";
}

@font-face {
    font-family: 'NradleyHandeITC';
    src: url('../src/fonts/BRADHITC.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="NradleyHandeITC"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NradleyHandeITC"]::before
{
    font-family: "NradleyHandeITC", cursive;;
    content: "NradleyHandeITC" !important;
}


.ql-font-NradleyHandeITC {
    font-family: "NradleyHandeITC";
}

@font-face {
    font-family: 'Cathedral';
    src: url('../src/fonts/CathedralRegular.woff2') format('woff2');
    
}
@font-face {
    font-family: 'ChopinScript';
    src: url('../src/fonts/kbchopinscript.woff2') format('woff2');
    
}

.ql-picker.ql-font .ql-picker-label[data-value="ChopinScript"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="ChopinScript"]::before
{
    font-family: "ChopinScript", cursive;;
    content: "ChopinScript" !important;
}


.ql-font-ChopinScript {
    font-family: "ChopinScript";
}

@font-face {
    font-family: 'NuptialScript';
    src: url('../src/fonts/NuptialScript.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="NuptialScript"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NuptialScript"]::before
{
    font-family: "NuptialScript", cursive;;
    content: "NuptialScript" !important;
}


.ql-font-NuptialScript {
    font-family: "NuptialScript";
}

@font-face {
    font-family: 'SheerElegance';
    src: url('../src/fonts/SheerEleganceRegular.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="SheerElegance"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="SheerElegance"]::before
{
    font-family: "SheerElegance", cursive;;
    content: "SheerElegance" !important;
}


.ql-font-SheerElegance {
    font-family: "SheerElegance";
}

@font-face {
    font-family: 'PenyaeLight';
    src: url('../src/fonts/PenyaeLightRegular.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="PenyaeLight"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="PenyaeLight"]::before
{
    font-family: "PenyaeLight", cursive;;
    content: "PenyaeLight" !important;
}


.ql-font-PenyaeLight {
    font-family: "PenyaeLight";
}
@font-face {
    font-family: 'PhyllisAttItalic';
    src: url('../src/fonts/PhyllisAttItalic.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="PhyllisAttItalic"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="PhyllisAttItalic"]::before
{
    font-family: "PhyllisAttItalic", cursive;;
    content: "PhyllisAttItalic" !important;
}


.ql-font-PhyllisAttItalic {
    font-family: "PhyllisAttItalic";
}

@font-face {
    font-family: 'Moderne';
    src: url('../src/fonts/ModerneRegular.woff2') format('woff2') ;

    
}
.ql-picker.ql-font .ql-picker-label[data-value="Moderne"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Moderne"]::before
{
    font-family: "Moderne", cursive;;
    content: "Moderne" !important;
}


.ql-font-Moderne {
    font-family: "Moderne";
}

@font-face {
    font-family: 'WendyMedium';
    src: url('../src/fonts/WendyMedium.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="WendyMedium"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="WendyMedium"]::before
{
    font-family: "WendyMedium", cursive;;
    content: "WendyMedium" !important;
}


.ql-font-WendyMedium {
    font-family: "WendyMedium";
}

@font-face {
    font-family: 'Wendyshand';
    src: url('../src/fonts/Wendyshand.woff2') format('woff2');
    
}
.ql-picker.ql-font .ql-picker-label[data-value="Wendyshand"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Wendyshand"]::before
{
    font-family: "Wendyshand", cursive;;
    content: "Wendyshand" !important;
}


.ql-font-Wendyshand {
    font-family: "Wendyshand";
}

.Gabriela {
    font-family: 'Gabriela', serif;
}
.bernhardModern {
    font-family: 'BernhardModern', serif;
}

.bickhamScriptPro {
    font-family: 'BickhamScriptPro', serif;
}

.nradleyHandeItc {
    font-family: 'NradleyHandeITC', serif;
}

.cathedral {
    font-family: 'Cathedral', serif;
}

.chopinScript {
    font-family: 'ChopinScript', serif;
}

.nuptialScript {
    font-family: 'NuptialScript', serif;
}

.sheerElegance {
    font-family: 'SheerElegance', serif;
}

.penyaeLight {
    font-family: 'PenyaeLight', serif;
}

.phyllisAttItalic {
    font-family: 'PhyllisAttItalic', serif;
}

.moderne {
    font-family: 'Moderne', sans-serif;
}

.wendyMedium {
    font-family: 'WendyMedium', sans-serif;
}

.wendysHand {
    font-family: 'Wendyshand', serif;
}
.nunito {
    font-family: 'Nunito', sans-serif;
}
.gabriola {
    font-family: 'Gabriola', sans-serif;
}

.raleway {
    font-family: 'Raleway', sans-serif;
}

.playfairDisplay {
    font-family: 'Playfair Display', serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.openSans {
    font-family: 'Open Sans', sans-serif;
}

.ebGaramond {
    font-family: 'EB Garamond', serif;
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}

.libreBaskerville {
    font-family: 'Libre Baskerville', serif;
}

.robotoCondensed {
    font-family: 'Roboto Condensed', sans-serif;
}

.oswald {
    font-family: 'Oswald', sans-serif;
}

.ql-font-nunito { font-family: 'Nunito', sans-serif; }
.ql-font-raleway { font-family: 'Raleway', sans-serif; }


body{
    overflow-x:hidden;
    /*font-family: 'Dancing Script', cursive!important;*/
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
}
p, span, a, h1, h2, h3, h4, h5, h6, div, label{
    /*font-family: 'Montserrat', sans-serif!important;*/
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0 !important;
}
.fs19{
    font-size: 19px;
    cursor: pointer;
}

.fs25{
    font-size: 25px;
}
.p10{
    padding: 10px;
}

.p10-b{
    padding: 10px 10px 0px 10px;
}

.fz-40{
    font-size:40px
}
.mrl15{
    margin-left: 15px;
}
.mr-10{
    margin-right: 10px;
}

.fr{
    float: right;
}
.fl{
    float: left;
}
.mt5{
    margin-top:5px;
}

.mb5{
    margin-bottom: 5px;
}
.mb10{
    margin-bottom: 10px;
}
.mt10{
    margin-top:10px;
}
.pb10{
    padding-bottom: 10px;
}
.wh100{
    width:100%;
    height:100%;
}

.w100{
    width: 100%;
}
.mr10{
    margin-right: 10px;
}

.pl50{
    padding-left: 50px;
}

.pl20{
    padding-left: 20px;
}

.mt10{
    margin-top: 10px;
}
.mt35{
    margin-top: 35px;
}
.mt30{
    margin-top: 30px;
}

.w90{
    width: 90%;
}

.flexEnd{
    justify-content: flex-end;
    display: flex;
}

.flexStart{
    justify-content: flex-start;
    display: flex;
}
.pointer{
    cursor: pointer;
}
.green-btn {
    background-color: green !important;
}

.red-btn {
    background-color: red !important;
}
.clGreen{
    color: green;
}
.StripeCheckoutSection{
    width: 100px;
    position: absolute;
}
.StripeCheckout{
    color: #fff;
    background: #4096ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    font-size: 14px;
    line-height: 1.5714285714285714;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
}
.StripeCheckout:active {
     color: #fff;
     background: #0958d9;
 }
.StripeCheckout:hover {
     color: #fff;
     background: #91caff;
 }
.StripeCheckout:focus-visible {
       outline: 4px solid #91caff;
       outline-offset: 1px;
       transition: outline-offset 0s, outline 0s;
   }
.rotate90deg{
    transform: rotate(90deg);
}
.ant-layout-header{
    padding-inline: 0px;
    height: 65px;
    background: white;
}
.ant-select .ant-select-clear{
    color: #ffa7a7
}
.ant-select .ant-select-clear :hover{
    color: #ff6f6f
}

.left-sidebar-covers{
    width: 100px;
    height:auto;
    margin-bottom: 5px;
    cursor: pointer;

}

.studio-info{
    float: left;
    margin-right: 30px;
}


.ant-tour-placement-bottom{
    z-index: 2071!important;
}
.ant-tour-placement-top{
    z-index: 2071!important;
}

.ant-tour-placement-left{
    z-index: 2071!important;
}
.ant-tour-placement-right{
    z-index: 2071!important;
}

.left-sidebar-covers-mini{
    width:100%;
    height:auto;
    margin-bottom: 5px;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid white;
}
.left-sidebar-covers-mini:hover,
.left-sidebar-covers-mini:active,
.left-sidebar-covers-mini:focus {
    border: 1px solid black;
}


.album_header{
    height: 50px;
    width: 100%;
    background: white;
    color: #1677ff;
    padding: 10px;
}

.logo{
    display: flex;
    justify-content: center;
}
.logo img{
    width: 100%;
    height: 60px;
    object-fit: contain;
    margin-left: 10px;
    background: #e6f4ff;
}

.studio_logo{
    width: 100px;
    height: 60px;
    object-fit: contain;
    background: #e6f4ff;
}

/*.header >.logo{*/
/*    float: left;*/
/*    width: 200px;*/
/*    height: 100%;*/
/*    background: white;*/
/*    padding: 4px 20px;*/
/*}*/




.ant-layout .ant-layout-sider-trigger {
    background: #e6f4ff;
    font-size: 22px;
    font-weight: bold;
    color: #1677ff;
}


.my_ant_icons_table{
    font-size: 25px;
    font-weight: bold;
    color: #223d8f;
    cursor: pointer;
}


.flexCenterClass{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-breadcrumb a {
    color: black;
    font-size: 16px;
}
.ant-breadcrumb {
    color: black;
    font-size: 16px;
}



.ant-menu .ant-menu-item{
    border-radius: 0px;
}
.ant-menu-inline .ant-menu-item {
    width: 98%;
}
.ant-menu-inline .ant-menu-submenu-title{
    width: 98%;
}
.ant-menu .ant-menu-submenu-title {
    border-radius: 0px;
}

.colapseButtonColor{
    color: #327dc3;
}

.colapseButton{
    font-size: 22px!important;
    width: 100%!important;
    height: 100%!important;
    padding-bottom: 8px;
    background: #FFFFFF!important;
}

.colapseButtonContainer{
    width: 64px;
    height: 64px;
    float: left;
    background: white;
}

.ant-layout {
    background: #e6f4ff;
}

.ant-form-item {
    margin-bottom: 0px;
}

.generate-uname-pass{
    font-size: 48px;
    text-align: center;
    padding-top: 40px;
    color: #327dc3;
    cursor: pointer;
}
.collapse-client{
    font-size: 16px;
    color: #223d8f;
    margin-top: 17px;
}

.formBtn{
    background: #327dc3;
}


.top_menu {
    display: flex;
    justify-content: flex-end;
}


.bg_white{
    background: white;
}

.bg_switcher_butoon_green{
    background: #9acd32;
}

.bg_switcher_butoon_grey{
    background: #c5c5c5;
}

.bg_switcher_butoon_green:hover{
    background: #9acd32!important;
}

.bg_switcher_butoon_grey:hover{
    background: #9acd32!important;
}



.studioModeContainer{
    float: left;
    background: #ffffff;
}

.ant-menu-inline .ant-menu-item{
    margin-block: 0px 4px;
}

.card-cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.card-cover {
    position: relative;
    overflow: hidden;
    height: 200px; /* set the desired height of the card cover */
}

.card-cover img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.ant-card-body{
    text-align: center;
}

.mrBot35{
    margin-bottom: 35px;
}

.ant-spin-nested-loading{
    /*overflow-x: scroll;*/
    min-height: 200px;
}
.priceTable .ant-spin-nested-loading{
    min-height: 100%;
}
.pdfTable .ant-spin-nested-loading{
    overflow-x: hidden;
}
.pdfTable .ant-table-cell {
     padding: 4px 16px !important;
 }
.event_titel{
    color: white!important;
    text-shadow: 0 0 4px cornflowerblue;
    padding: 15px;
}
.buttons-container {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    margin-top: auto;
}


.Board{
    width: 500px;
    height: 500px;
    border: 1px solid green;
    position: absolute;
    left: 630px;
    top: 21px;
}


.flip-book-container {
    /*width: 100%;*/
    width: 1200px;
    margin: 100px auto; /* Center the flip book horizontally */
}


.album-left-page-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 4px 0px 4px 4px;
}

.album-right-page-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 4px 4px 4px 0px;
}

.album-right-cover-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.album-front-right-cover-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 16px 20px 20px 0px black;
}

.transform_preview{
    width: 100%;
    height: 100%;
}

.corner{
    z-index: 999;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) -20px 20px 20px 20px, rgba(0, 0, 0, 0.1) 20px 20px 20px 20px, rgba(0, 0, 0, 0.45) -20px 11px 10px -5px, rgba(0, 0, 0, 0.45) 20px 11px 10px -5px;
}

.album-front-left-cover-image2{
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: -10px 14px 20px 0px black;
}

 .album-front-right-cover-image2{
    width: 100%;
    height: 100%;
    object-fit: cover;

    box-shadow: 16px 14px 20px 0px black
}

.album-left-cover-image{
    width: 100%;
    height: 100%;
    object-fit: cover;

    box-shadow: 16px 20px 20px 0px black;
}


.album-left-cameo-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hidden{
    display: none!important;
}


.comment-area{
    width: 200px;
    position: absolute;
    z-index: 999;
}



.flip-book-main {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat:no-repeat;
    background-position:center;
}

.flip_book_main{

    /*position: absolute;*/
    /*left: 100px;*/
    /*top: 100px;*/
    /*width:600px;*/
    /*height:500px;*/
}

.comment-area-icon{
    color: #68cf30;
    font-size:30px;
}

.page.page-cover.page-cover-bottom {
    box-shadow: inset 0 0 30px 0 rgba(36,10,3,.5), 10px 0 8px 0 rgba(0,0,0,.4);
}

.page.page-cover {
    background-color: #e3d0b5;
    color: #785e3a;
    border: 1px solid #998466;
}

.page.page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}
.album-notifications-select {
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    background-color: #ffffff;
    padding: 10px;

}

.header-title{
    color: #327dc0;
}
/*Message Div*/
.message-orange {
    position: absolute;
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
    width: 200px;
    max-height: 150px;
    overflow-y:auto;
    text-align: left;
    font: 400 .9em 'Open Sans', sans-serif;
    border: 1px solid #dfd087;
    border-radius: 10px;
    top: 35px;
}

.message-content {
    padding: 0;
    margin: 0;
}



.message-timestamp-right {
    font-size: .85em;
    font-weight: 300;
   margin-top:10px;
    text-align: right;
}

.message-timestamp-left {
    position: absolute;
    font-size: .85em;
    font-weight: 300;
    bottom: 5px;
    left: 5px;
}

.message-blue:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #A8DDFD;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
}

.message-blue:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 17px solid #97C6E3;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    top: -1px;
    left: -17px;
}
.album-sidebar {
    padding: 20px 24px 32px;
    border-left: 1px solid rgb(239, 240, 241);
    height: 100vh;
    overflow-y: auto;
}

/* Webkit browsers (Chrome, Safari, Opera) */
.album-sidebar::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

.album-sidebar::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scroll thumb */
    border-radius: 3px; /* Rounded corners for the scroll thumb */
}

.album-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scroll thumb when you hover over it */
}


.album-sidebar-secondary-title{
    padding: 10px;
}

.album-sidebar-image-title{
    font-weight: 600;
    text-transform: none;
    line-height: 1.2;
    font-size: 14px;
    letter-spacing: normal;
    margin: 0px;
    color: rgb(35, 38, 41);
}


.border-line-bottom{
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(239, 240, 241);
    margin-bottom: 12px;
}


.album-sidebar-image-desc{
    font-weight: 400;
    text-transform: none;
    line-height: 1.2;
    letter-spacing: normal;
    margin: 0px;
    color: rgb(78, 81, 85);
    font-size: 12px;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 8px;
}

.p50{
    padding: 0px 50px 50px 50px;
}


.album-sidebar-header{
    padding: 20px 24px 10px;
    height: 100%;
    border-left: 1px solid rgb(239, 240, 241);
    border-bottom: 1px solid rgb(239, 240, 241);
}

.album-header-bg{
    background: #223d8f;
    color: white;
}

.album-btn{
   background:#223d8f;
}


.album-header-text{
    text-align: right;
    padding-top: 8px;
}

.album-side-text-header{
    text-align: right;
}

.p25-10-0-10{
    padding: 25px 10px 0px 10px;
}




.ratio-5x5::before {
    content: "";
    display: block;
    padding-top: 100%; /* aspect ratio 1:1 */
}

.ratio-5x7::before {
    content: "";
    display: block;
    padding-top: 140%; /* aspect ratio 5:7 */
}

.ratio-8x10::before {
    content: "";
    display: block;
    padding-top: 125%; /* aspect ratio 8:10 */
}

.ratio-10x8::before {
    content: "";
    display: block;
    padding-top: 80%; /* aspect ratio 10:8 */
}



.cameo-type-seven{
    position: absolute;
    bottom: 10%;
    left: 10%;
    width: 80%;
    height: 30%;
    background: whitesmoke;
  
}
.cameo-type-six{
    position: absolute;
    top: 10%;
    right: 10%;
    width: 30%;
    height: 80%;
    background: whitesmoke;
    
}
.cameo-type-five{
    position: absolute;
    top: 35%;
    left: 10%;
    width: 90%;
    height: 30%;
    background: whitesmoke;
    
}

.cameo-type-four{
    position: absolute;
    top: 0px;
    right: 0.5px;
    width: 30%;
    height: 100%;
    background: whitesmoke;
    
}
.cameo-type-tree{
    position: absolute;
    bottom: 1.5px;
    right: 0;
    width: 90%;
    height: 30%;
    background: whitesmoke;
   
}
.cameo-type-two{
    position: absolute;
    top: 30%;
    left: 30%;
    width: 45%;
    height: 45%;
    background: whitesmoke;
}
.cameo-type-one{
    position: absolute;
    top: 0;
    left: 10%;
    width: 90%;
    height: 99.9%;
    background: whitesmoke;
}


.album-stamping-child {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #d0d0d0;
}

.resizable-input {
    border: none;
    resize: both;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.spamping-icons{
    font-size: 50px;
    color: #223d8f;
    text-align: center;
    margin-right: 5px;
    cursor: pointer;
}
.stamping-pos-border{
    border: 4px solid #223d8f;
}

.stamping-content{
    position: absolute;
}

.stamping-input{
    padding: 0;
    width: 100%;
    background-color: #e6f4ff;
}

.spamping-colors{
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
}

.stamping-oval{
    width: 32px;
    height: 32px;
    background: #ffd700;
    border-radius: 20px;
    margin: 0px auto;
}

.bg-gray{
    background: #f3f3f3;
}


.center-stamping {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.bottom-right-stamping {
    position: absolute;
    right: 2%;
    bottom: 2%;
}

.bottom-center-stamping {
    position: absolute;
    left: 50%;
    bottom: 2%;
    transform: translateX(-50%);
}

.stamping-font-size{
    width: 100%;
    margin: 20px 0px;
}
.stamping-font-family{
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}

.stamping-font-family-border{
    border: 1px solid black;
}
.priceListTableEdit{
    width: 1400px !important;
    top: 22px !important;
    align-items: center;
padding: 10px;
}
.EventByClinet{
    width: 900px !important;
    top: 22px !important;
    align-items: center;
}
.PDFModal{
    width: 1300px !important;
    top: 22px !important;
    align-items: center;
}

.PDFModal .ant-modal-content {
    padding: 25px 50px !important;
}
.ql-toolbar {
    line-height: 28px;
}

.CardMainSider .ant-layout-sider-children{
    height: 100% !important;
}



.my-masonry-grid{
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0px;
    margin-right: 5px;
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 5px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 5px;
}

.my-masonry-grid_column > div > img {
    width: 100%;
}

.galleryPagination .ant-pagination-options{
    display: none;
}

.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub
{
    width: 300px;
}
.helperShow,
.ant-tour-next-btn,
.byBut{
    background: #223d8f;
    color: white;
    cursor: pointer;
    border-radius: 0 !important;
}
.byButI{
    cursor: pointer;
    font-size: 21px;
    margin-left: 20px;
}
.ChatIcon{
    padding: 10px;
    font-size: 20px;
    margin-bottom: 10px;
}
.ChatIcon:hover{
    background: #39539b;
}
.ant-tour-prev-btn{
    cursor: pointer;
    border-radius: 0 !important;
}
.helperShow .ant-checkbox-inner{
    height: 25px !important;
    width: 25px !important;
    border: 2px solid #223d8f
}

.modalCart{
    width: 65%!important;

}
.modalCart >.ant-spin-nested-loading{

    height: 800px;
}


input::-webkit-inner-spin-button{
display: none;
}



.deleteInput .ant-btn-icon svg {
    color: #327dc3 !important;
}
.deleteInput .ant-input-clear-icon {
    color: #ffa7a7 !important;
}
.deleteInput .ant-input-clear-icon:hover{
    color: #ff6f6f !important;
}
.ant-layout-footer {
    padding:0;
}

.ql-editor-small {
    transform: scale(0.45); /* Adjust scale as needed */
    /*font-size: 50%;*/

}

.shopping_cart_text{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: normal;
    overflow: hidden;

}

/*Gallery*/

.InfiniteScroll{
    margin-top: 200px;
    display: flex;
    margin-left: 0px;
    width: auto;
    margin-right: 5px;
}
.galleryCheked{
    margin: 0 5px;
}
.galleryCheked .ant-checkbox .ant-checkbox-inner {
    width: 25px !important;
    height: 25px !important;
}
.galleryCheked .ant-checkbox .ant-checkbox-inner:after {
    inset-inline-start: 30.5% !important;
}
.ant-result .ant-result-subtitle {
    color: #000;
    font-size: 21px;
    font-weight: 700;
    line-height: 1.5714285714285714;
    text-align: center;
}

/* Font Weight Styles */
.normalFontWeight {
    font-weight: 500;
    cursor: pointer;
}

.boldFontWeight {
    font-weight: 700;
    cursor: pointer;
}

.card-front-flip {
    transform: perspective(600px) rotateY(0deg);
}

.card-back-flip {
    transform: perspective(600px) rotateY(180deg);
}

.extraBoldFontWeight {
    font-weight: 900;
    cursor: pointer;
}

/* Font Style Styles */
.normalFontStyle {
    font-style: normal;
    cursor: pointer;
}

.italicFontStyle {
    font-style: italic;
    cursor: pointer;
}

/* Active Font Weight, Font Style, and Font Family */
.activeFontWeight,
.activeFontStyle,
.activeFontFamily {
    cursor: pointer;
}

/* Hover Effect */
.normalFontWeight:hover,
.boldFontWeight:hover,
.extraBoldFontWeight:hover,
.normalFontStyle:hover,
.italicFontStyle:hover,
.activeFontWeight:hover,
.activeFontStyle:hover,
.activeFontFamily:hover {
    cursor: pointer;
}
.chat-input .chat-input {
    width: 100% !important;
}.chat-input .ant-input-affix-wrapper  {
    width: 100% !important;
}
.selected_panel .ant-collapse-header{
    padding:  10px 16px !important;
}
.chat-messages .ant-card{
    margin-bottom: 5px;
}
.answer .ant-card .ant-card-body {
    padding: 10px  0 0!important;
    text-align: start;
    width: 100%;
}
.question .ant-card .ant-card-body {
    padding: 10px 0 0;
    text-align: end;
    width: 100%;
}


.DropdownOpenBlock{
    display: block;
}
.DropdownOpenNone{
    display: none;
}
.DropdownMenu{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    z-index: 1000;
    position: absolute;
    min-width: 350px;
    width: 100%;
    max-width: 100%;
}
.DropdownImages{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    z-index: 100000;
    position: absolute;

}





@media only screen and (max-width:1200px){
    .modalCart{
        width: 75%!important;

    }

}

@media only screen and (max-width:840px){
    .galleryPagination.ant-pagination .ant-pagination-item {
        min-width: 23px !important;
        margin-inline-end: 5px !important;}

}
@media only screen and (max-width:800px){
    .my-masonry-grid {
        margin-left: 0px;
        width: auto;
        margin-right: 5px;
    }
    .my-masonry-grid_column {
        padding-left: 5px; /* gutter size offset */
    }
    .my-masonry-grid_column > div {
        margin-bottom: 5px; /* space between items */
    }

    .my-masonry-grid_column > div > img {
        margin-bottom: 5px; /* space between items */
    }

}
@media only screen and (max-width:768px){
    .flip-book-container {
        max-width: 300px;
    }
    .open-gallery-col{
        padding-left: 3rem!important;
    }
    .gallery-user-name p{
        font-size: 28px;
    }

    .buttons-container {
        justify-content: center;
    }
    .helperMode{
        display: none;
    }
    .modalCart{
        width: 85% !important;
    }
}
@media only screen and (max-width:739px){
    .header >.logo {
        float: left;
        height: 100%;
        background: white;
        padding: 0px 0px;
        flex: 0 0 80px;
        max-width: 80px;
        min-width: 80px;
        width: 80px;
    }
}
@media only screen and (max-width:577px){

    .flip-book-container {
        max-width: 200px;
    }
    .ril__inner {
        top: 0;
        left: 14%;
        right: 0;
        bottom: 0;
    }

}

@media only screen and (max-width:438px){
    .colapseButtonContainer{
        display: none;
    }
    .header >.logo {
        float: left;
        height: 100%;
        background: white;
        padding: 0px 0px;
        flex: 0 0 80px;
        max-width: 80px;
        min-width: 80px;
        width: 80px;
    }
    .labDashbordMenu  {
        flex: 0 0 40px !important;
        max-width: 40px !important;
        min-width: 40px !important;
        width: 40px !important;
    }
    .labDashbordLayout{
        padding: 0 10px 10px !important;
    }
    .studioModeLabel {
        font-size: 10px;
        padding: 7px;
    }

    .galleryCheked .ant-checkbox .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
    }
}