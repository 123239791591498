.scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.page-image {
    width: 180px;
    height: 90px;
    object-fit: cover;
    margin-right: 16px;
}

.page-label {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.album-component {
    width: 180px;
    height: 90px;
    position: relative;
}