.createFolder{
    width: 50% !important;
    displey:flex;
    justify-content: center;
    align-items: center;
}
.contractInput{
    width: 100% !important;
}
.selected{
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    background-color: #ffffff;
    padding: 10px;
    min-height: 300px;
}
.selectedMain{
    width: 100%;
    margin-bottom: 10px;
    text-align:center;
}
.selectedOption{
    text-align:center;
}
.selectedCard{
    text-align:center;
    width:100%;
}
.selectedAlbum{
    text-align:center;
    width:100%;
}
.selectedPrice{
    text-align:center;
    width:100%;
}

.menu{
    display: inline-flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-between;

}
.mainMenu{
    background: transparent!important;
}

.menu_icon svg{
    color:#223d8f !important;
}
.menu_button{
    border: transparent;
    padding: 0;
    background: transparent;
}
.menu_button svg:hover {
    color: #1677ff !important;
    border: transparent;
    padding: 0;
    background: transparent;
}

.DropdownMenu{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}
.DropdownItem{
    display: flex;
    align-items: center;
    border-bottom: 4px solid transparent;
}
.DropdownItem:hover{
  cursor: pointer;
    border-bottom: 4px solid #91caff;
    transition: border 0s, outline 0s;
}

