.spread-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
}

.lines {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    height: 2px;
    position: absolute;
}

.line-cord-plus{
    left: 0px;
    top: 0px;
    transform-origin: 0px 100%;
    transform: rotate(40deg);
}

.line-cord-minus{
    left: 0px;
    bottom: 0px;
    transform-origin: 0px 100%;
    transform: rotate(-40deg);
}

.rline-cord-plus{
    right: 0px;
    top: 0px;
    transform-origin: 100% 0px;
    transform: rotate(320deg);
}

.rline-cord-minus{
    right: 0px;
    bottom: 0px;
    transform-origin:100% 0px;
    transform: rotate(-320deg);
}

.page-shadow-spread {
    position: absolute;
    width: 1%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
}

.page-shadow-scale-left{
    right: 0px;
    transform: scaleX(-1);
    transform-origin: center center;
}

.page-shadow-scale-right{
    left: 0px;
    transform: scaleX(-1);
    transform-origin: center center;
}


.spread-left, .spread-right, .spread-spine {
    height: 100%;
}

.spread-left, .spread-right {
    position: relative;
    width: 50%;
    background-color: rgb(74, 56, 47);
    background-image: url('https://cdn.dragdrop.design/assets/material/vintage_leather_aviator_tile_1600.jpg');
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1.5901px 1.19258px, rgba(0, 0, 0, 0.08) -1.32509px 3.18021px 2.38515px;
}

.spread-spine {
    width: 4%;
    height: 100%;
    position: relative;
    /*z-index: -1;*/
}

.spread-spine-transform {
    position: absolute;
    overflow: hidden;
    height: 100%;
    transform-origin: left center;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2.14433px 1.60825px;
}
.pos-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.full-spread-gray {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 4px;
    overflow: hidden;
    background: url(https://cdn.dragdrop.design/album/assets/endleaf/white_textured_endleaves.jpg) left top / 150px 150px repeat;
    width: 97.4093%;
    height: 94.8454%;
    border-radius: 0px;
    z-index: 2;
}

.full-spread-white {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 4px;
    overflow: hidden;
    background: white;
    width: 97.4093%;
    height: 94.8454%;
    border-radius: 0px;
    z-index: 2;
}

.mid-line{
    width: 2px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.5));
    z-index: 2;
    height: 95%;
}



.spread-spine-transform-bg {
    z-index: 2;
    width:100%;
    height:100%;
    background-color: rgb(74, 56, 47);
    background-image: url('https://cdn.dragdrop.design/assets/material/vintage_leather_aviator_tile_1600.jpg');
    background-size: cover;
    box-shadow: inset rgba(0, 0, 0, 0.08) 10px -5px 13px 3px, inset rgba(0, 0, 0, 0.08) -8px -7px 20px 13px;
    transform: perspective(2500px) translateZ(-40px);
}




.spread-spine_black_shadow {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5.56536px 4.77031px, rgba(0, 0, 0, 0.12) 0px 9.54062px 14.3109px, rgba(0, 0, 0, 0.45) -12px 0px 12px -6px, rgba(0, 0, 0, 0.3) 9.6px 0px 9.6px -4.8px;
}

.spread-spine_white_shadow {
    height: 100%;
    width: 100%;
    background-color: rgb(255 255 255);
    opacity: 0.08;
    mix-blend-mode: soft-light;
}
