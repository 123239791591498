.scrollSplit {
    overflow-x: hidden!important;
    overflow-y: hidden!important;
    white-space: nowrap;
    height: 160px;
    margin: 20px 10px;
}

.scrollSplit .infinite-scroll-component{
    height: 160px !important;
    overflow-y: hidden!important;
}
.scrollSplit_div{
    padding: 10px;
    display: inline-block;
    height: 150px;
}

.scrollSplit_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.5;
    padding: 5px;
}
.scrollSplit_img:hover {
    cursor: pointer;
    opacity: 1;
    border: solid 2px #223d8f;
}
.Compare{
    height:100%;
    margin: 40px auto;
    display: flex;
    justify-content: center;
}

.CompareContent{
    width:  50% !important;
    padding: 5px;
    border: 1px solid #ADADAD;
}
.CompareImage{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.contractBtn{
    background: var(--colorBtn);
    color: #fff;
}