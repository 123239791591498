

.ant-switch{
    background: rgb(146 146 146);
    line-height: 29px;
    width: 160px;
    height: 30px;
}

.ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    margin-top: -29px;
}

.ant-switch.ant-switch-checked {
    background: #007a3d;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #009c4e;

}

.ant-switch .ant-switch-inner .ant-switch-inner-checked, .ant-switch .ant-switch-inner .ant-switch-inner-unchecked{
    font-size: 14px;
    font-weight: bold;
}



.ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked {
    font-size: 14px;
    font-weight: bold;

}

.ant-switch:hover:not(.ant-switch-disabled) {
    background: rgb(203 203 203);
}

.ant-switch .ant-switch-handle{
    top: 3px;
    inset-inline-start: 2px;
    width: 24px;
    height: 24px;
}

.ant-switch .ant-switch-handle::before {
    border-radius: 12px;
}

/*.ant-layout .ant-layout-header{*/
/*    line-height: 0px;*/
/*}*/

.headers{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

/* second navbar gallery */

.second-header nav{
    background-color: #dfdddd;
}
.tricolor-logo-nav{
    width: 200px;
    padding-left: 30px!important;
}
.create-folder{
    color: #223D8F!important;
    border: 1px solid;
    padding: 10px!important;
    border-radius: 5px;
}
.folder-icon{
    font-size: 20px;
}