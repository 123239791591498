.table-striped-rows tr:nth-child(2n) td {
    background-color: #f2f4f8;
    /*width:100%*/
    font-weight: bold;
}
.table-striped-rows .table-id:nth-child(1) {
    /*width:100px*/
    font-weight: bold;
}


.table-striped-rows {
    /*width:100px*/
    font-weight: bold;
}
.table-striped-rows thead {
    background-color: #f1f1f1;
    font-weight: bold;
}

.mrTopBot30{
    margin-top: 30px;
    margin-bottom: 30px;
}
.mrTopBot20{
    margin-top: 20px;
    margin-bottom: 20px;
}

.mrTopBot10{
    margin-top: 10px;
    margin-bottom: 10px;
}
.ant-spin-nested-loading{
    overflow-x: scroll;
    width: inherit;
}
/*.card-table .ant-spin-nested-loading, .album-table .ant-spin-nested-loading{*/
/*    width: fit-content!important;*/
/*    overflow-x: hidden;*/
/*}*/
/*.card-table .ant-pagination, .album-table .ant-pagination{*/
/*    display: none;*/
/*}*/

/*.studio-table-rows .ant-table-thead >tr>th{*/
/*    color: rgb(255 255 255 / 88%);*/
/*    background: #223d8f;*/
/*}*/

/*.studio-table-rows  .ant-table-thead th.ant-table-column-has-sorters:hover {*/
/*    background: #ffffff;*/
/*    color: #223d8f;*/
/*}*/

/*.studio-table-rows .ant-table-column-sorter {*/
/*    color: rgb(255 255 255);*/
/*}*/

/*.studio-table-rows  .ant-table-column-sorters:hover .ant-table-column-sorter {*/
/*    color: rgb(34 61 143);*/
/*}*/

/*.studio-table-rows  .ant-table-thead th.ant-table-column-sort {*/
/*    background: #ffffff;*/
/*}*/
/*.studio-table-rows .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters >.ant-table-column-sorters > .ant-table-column-title{*/
/*color:#223d8f;*/
/*}*/

/*.studio-table-rows  .ant-table-column-sorter-up.active,.studio-table-rows  .ant-table-column-sorter-down.active {*/
/*    color: #223d8f;*/
/*}*/

/*.studio-table-rows  .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{*/
/*    background-color: transparent;*/
/*}*/

/*.studio-table-rows .ant-btn-primary {*/
/*    background-color: #223d8f;*/
/*    box-shadow: 0px 0px 0px 4px rgb(255 255 255);*/
/*}*/

/*.studio-table-rows .ant-menu-dark .ant-menu-item-selected {*/
/*    background-color: #0d499c;*/
/*}*/

.studio-table-rows  .ant-table-tbody >tr.ant-table-row:hover>td, .studio-table-rows .ant-table-tbody >tr >td.ant-table-cell-row-hover {
    background: #d0d0d0;
    color: #000000;

}

/*.studio-table-rows  .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr.ant-table-row:hover>td:first-child{*/
/*    border-start-start-radius: 0px;*/
/*    border-end-start-radius: 0px;*/
/*}*/

/*.studio-table-rows  .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr.ant-table-row:hover>td:last-child{*/
/*    border-start-start-radius: 0px;*/
/*    border-end-start-radius: 0px;*/
/*}*/
/* .studio-table-rows  .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr.ant-table-row:hover>td:last-child,  .studio-table-rows .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr >td.ant-table-cell-row-hover:last-child, .studio-table-rows .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr.ant-table-row.ant-table-row-selected>td:last-child {*/
/*    border-start-end-radius: 0px;*/
/*    border-end-end-radius: 0px;*/
/*}*/
/*.studio-table-rows .ant-btn-primary:not(:disabled):hover {*/
/*    color: #fff;*/
/*    background-color: #0d499c;*/
/*}*/
