.Logout{
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 23px;
    border: none;
    background: transparent;
    justify-content: end;
}


@media only screen and (max-width:929px) {

    .studioInfo div{
        font-size: 12px;
        margin-bottom: 4px;
    }
}@media only screen and (max-width:799px) {

    .studioInfo {
     display: none;
    }
}


@media only screen and (max-width:730px){
    .headerBlock {
        display: none
    }
}
@media only screen and (max-width:500px){
    .print_header .logo img{
        height: 43px;
    }
}
