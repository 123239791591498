.scrollableDiv {
  height: auto;
  max-height: 700px;
  width: 100%;
  overflow-y: scroll!important;
  overflow-x: hidden!important;
  padding: 0;
}



.scrollableDiv::-webkit-scrollbar {
  width: 7px !important;
}
.scrollableDiv::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.scrollableDiv::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}


.ant-table-containe::after {
  display: none;
}
.ant-table-containe::after {
  display: none;
}
.ant-table-wrapper::after {
  display: none;
}


.modalEdit .ant-modal-content {
  max-height: 100%;
  overflow-y: auto;
}

.modalEdit {
  width: 780px !important;
  top:10px !important;
}
.modalEditCard {
  width: 900px !important;
  top:10px !important;
}

.flexCenterClassAdd{
  display: none !important;

}
.flexCenterClassSave{
  display: block !important;
  width: 80% !important;
}

.cartEdit{
  border-radius: 0px;
  color: rgb(34 61 143) !important;
  border:  2px solid rgb(34 61 143);
  font-weight: bold;
  width: 100%;
  padding: 0 4px;
}

.cartDelete{
  border-radius: 0px;
  color: #ff4d4f !important;
  border:  2px solid #ff4d4f;
  font-weight: bold;
  width: 100%;
  padding: 0 4px;
}
.cartDelete  span,
.cartEdit span{

  display: inline-flex !important;
}

.cartDelete:hover{
  border-radius: 0px;
  color: red !important;
  border:  2px solid red;
  font-weight: bold;
}

.cartEdit:hover{
  color: rgb(34 61 143) !important;
  border-color: rgb(34 61 143) !important;
}


.cartPlus .plus-minus {
  width: 30px !important;
  height: 25px !important
}
.imgCart{
  width: 150px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.CheckoutModal{
  top: 0;
  width: 100% !important;
  displey:flex;
  justify-content: center;
  align-items: center;
}
.formCheckout .ant-form-item .ant-form-item-label {
  text-align: start !important;
}
.itemStrong{
  justify-content:  end;
  color: green;
  width: 100%;
}

.itemP{
  text-align: end;
  font-size: 14px;
  width:100%;
  border-radius: unset;
  color: black;
}
.textSpace{
  width: 100%;
  line-height: 8px;
  text-align: start;
  display: flex;
  justify-content: end;

}
.orderShop{
  padding: 0 10px;
}
.ant-form-item-label{
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width:992px){
  .orderDiv{
    display: flex;
    flex-direction: column-reverse;
  }
.orderShopHr{
  display: none;
}

}
