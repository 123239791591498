.galleryBtnClientMode {
    margin-right: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 ;
    border: none;
    color: black;
    background: transparent;
    border-radius: 0;
    border-bottom: 4px solid #91caff;
    transition: border 0s, outline 0s;
}

@media only screen and (max-width:438px){
    
    .galleryBtnClientMode{
        font-size: 15px;
        margin-right: 10px;
    }
}