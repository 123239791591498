.front-back-buttons{
    text-align: center;
    color: #000000;
    font-weight: bold;
    cursor: pointer;
    border-right: 1px solid #f1f1f1;
}

.front-back-buttons:hover{
    text-align: center;
    color: #223d8f;
    font-weight: bold;
    border-bottom: 3px solid #223d8f;
}

.front-back-buttons.active{
    text-align: center;
    color: #223d8f;
    font-weight: bold;
    border-bottom: 3px solid #223d8f;
}
.card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87%;
    flex-direction: column;

}

.card-main{
    width: 100%;
    height: 100%;
    max-height: 680px;
    min-height: 680px;
    align-items: center;
    display: flex;
    position: relative;
    transform: scale(0.7);
}

.card-div{
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CardMainSider{
    background-color: white;
    padding: 20px ;
    height: 100% !important;
}
.CardMainRow{
    display: flex;
    flex-direction: column;
    height: 100% !important;
}
.selected{
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
}

.scroll-main-panel {
    background-color: rgb(255, 255, 255) !important;
}
.selectedImage_panel1 {
    width: 100%;
    margin-bottom: 20px;
    max-height: 300px;
}

.panel2 {
    width: 100%;
    margin-bottom: 5px;
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
    .addButton922 {
        width: 50%;
    }
}
@media only screen and (max-width:1200px){

    .divNone{
        display: none;
    }
}
@media only screen and (max-width:700px){
    .card-main{
        transform: scale(0.7);
        min-height: 620px;
    }
}@media only screen and (max-width:630px){
    .card-main{
        transform: scale(0.6);
    }
}
@media only screen and (max-width:500px){
    .card-main{
        transform: scale(0.6);
        min-height: 550px;
    }
}
@media only screen and (max-width:400px){
    .card-main {
        transform: scale(0.3);
        min-height: 250px;
    }

}