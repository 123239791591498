.scrollableDiv {
  max-height: 400px;
  width: 100%;
  overflow-y: scroll!important;
  overflow-x: hidden!important;
}

.scrollableDiv::-webkit-scrollbar {
  width: 7px !important;
}
.scrollableDiv::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.scrollableDiv::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.scrollableDiv::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
.selected_panel{
  border-radius: 7px;
  margin-bottom: 8px;
}
.selectedImage_div{
  position: relative;
  height: 100%;
  max-height: 200px;
  padding: 10px;
}

.selectedImage_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
  padding: 5px;
}
.selectedImage_img:hover {
  cursor: pointer;
  opacity: 1;
  border: solid 2px #223d8f;
}


.selectFolderImages{
  width: 100%;
  margin-bottom: 10px;
}

.checkedCart{
  top: 3px;
  right: 8px;
  position: absolute;
}
