.selectedImage_selectType {
    width: 100%;
    height: 105px;
    background: no-repeat center / cover;
    object-fit: contain;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    box-shadow: 3px 3px 5px #aaaaaa;
    cursor:pointer;
}
.selectedImage_selectType p{
    width: 100%;
    height: max-content;
    background-color: white;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    justify-content: center;
}
.selectTypeDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}