.containerDiv{
  background-color: #eeeeee;
}
.imageDiv {
  width: 100%;
  height: 100%;;
  display: flex;
  align-items: center;
  justify-content: center;
  scale:0.9;
}
.switchContainer{
  display: flex;
  justify-content: end;
  align-items: center;
}

.main-image-button{
  border-radius: 0px;
  border: none;
  background: rgb(34 61 143);
  color: white;
  margin: 5px 0 0 10px;

}
.main-checkout-button{
  border-radius: 0px;
  border: none;
  background: #4daf50;
  color: white!important;
  margin: 5px 0 0 10px;
}

.main-image-button:hover{
  color: white!important;
  background: rgba(39, 71, 166, 0.87) !important;
}
.main-checkout-button:hover{
  color: white!important;
  background: #6ae06d !important;
}

.wall {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wall1 {
  position: relative;
  width: 85px;
  height: 75px;
  background: url("https://mir-s3-cdn-cf.behance.net/project_modules/1400/f7f2b688061429.5dcaf7c4a1266.jpg");
  background-position: center;
  background-size: cover;
}

.stock1 {
  position: absolute;
  bottom: 10px;
  left: 5px;
  max-width: 35px;
  max-height: 40px;
}
.wall2 {
  position: relative;
  width: 85px;
  height: 75px;
  background: url("https://ae04.alicdn.com/kf/S2cd83f3e854b4076a0f97eb90d9d3f99K.jpg");
  background-position: center;
  background-size: cover;
}
.stock2 {
  position: absolute;
  top: 3px;
  left: 10px;
  max-width: 35px;
  min-width: 20px;
  min-height: 20px;
  max-height: 40px;
}

.wall3 {
  position: relative;
  width: 85px;
  height: 75px;
  background: url("https://static.asianpaints.com/content/dam/asianpaintsbeautifulhomes/202303/15-small-bedroom-design-ideas-to-create-a-stylish-space/title-small-monochrome-bedroom.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.stock3 {
  position: absolute;
  top: 4px;
  left: 20px;
  max-width: 40px;
  min-height: 20px;
  max-height: 25px;
}
.wall4 {
  position: relative;
  width: 85px;
  height: 75px;
  background: url("https://media.istockphoto.com/id/183419746/photo/gerbera-flower-in-white-vase.jpg?s=612x612&w=0&k=20&c=xcsjcOZl-NxKiQs8KbhjL6q3XDqXYzjseK7qL-ctwoQ=");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.stock4 {
  position: absolute;
  top: 5px;
  right: 5px;
  max-width: 50px;
  max-height: 40px;
}


.text {
  font-size: 13px;
}

.modalDiv {
  position: relative;
  width: 470px;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.modalImg0 {
  position: absolute;
  bottom: 45px;
  left: 30px;
  max-width: 150px;
  min-width: 100px;
  box-shadow: 3px 3px 5px #aaaaaa;
}
.modalImg1 {
  position: absolute;
  top: 10px;
  left: 40px;
  max-width: 200px;
  min-width: 100px;
  max-height: 200px;
  box-shadow: 3px 3px 5px #aaaaaa;
}

.modalImg2 {
  position: absolute;
  top: 20px;
  left: 100px;
  max-width: 200px;
  min-width: 100px;
  max-height: 150px;
  box-shadow: 3px 3px 5px #aaaaaa;
}
.modalImg3 {
  position: absolute;
  top: 45px;
  right: 30px;
  max-width: 200px;
  min-width: 150px;
  box-shadow: 3px 3px 5px #aaaaaa;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setCheck{
  margin-left: 10px;
}

.TextArea{
  align-items: center;
  width: 100%;
}