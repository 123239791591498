.help-chat-container {
    max-width: 450px;
   min-width: 300px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.chat-messages {
    padding: 10px;
    max-height: 600px;
    overflow-y: auto;
    font-size: 22px;
    scrollbar-width: thin;
    scrollbar-color: grey #f3efef;
}


.question {
    font-size: 16px;
    text-align: right;
}
.message-contents{
    padding: 10px ;
    background-color: #f3efef;
    border-radius: 5px;
    width: 90%;
    margin-left: 10%;
    text-align: end;
    align-items: end;
    display: flex;
    flex-direction: column;

}
.message-contents .ant-space-item{
    width: 100%;
}
.file{
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.timestamp,
.ClinetInfo{
    font-size: 12px;
}
.answer {
    display: flex;
    font-size: 16px;
    flex-direction: column;
}
.answer-content{
    padding: 10px ;
    border-radius: 5px;
    background-color: #c6e892;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.chat-input {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 10px;
}

.chat-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    width: 100%;
}

.chat-input button {
    background-color: #007bff;
    color: #fff;
    padding: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-input button:hover {
    background-color: #0056b3;
}
