:root{
    --colorBtn:#327dc3;
}
.contentStyle  {
    height: 160px;
    color:#fff;
    line-height: 160px;
    text-align: center;
    background: #364d79;
}
.GalleryLogo{
    background: #e6f4ff;
}
.GalleryLogo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fixedHeader{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999; /* Adjust the z-index as needed */
    transition: top 0.3s ease;
}
.client_header{
    width: 100%;
    background: #e6f4ff;
    padding:10px;
    height: 100%;
    align-items: center;
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));

}

.client_icon {
    width: 100%;
    background: #fff;
    padding: 10px;
    height: 100%;
    align-items: center;
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    z-index: 9999;
}

.logoGallery{
    float: left;
    width: 200px;
    height: 100%;
}
.logoGallery img {
    width: 100%;
    height: 140px;
}


.client_right{
    display: flex;
    justify-content: end;
}
.Row{
    text-align: center;
    align-items: center;
}

.studioInfo{
    margin-right: 2px;
    font-size: 14px;
}
.galleryBtn{
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 ;
    border:  transparent ;
    color:black;
    background: transparent;
}


.galleryBtnClientMode {
    margin-right: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 ;
    border: none;
    color: black;
    background: transparent;
    border-radius: 0;
    border-bottom: 4px solid #91caff;
    transition: border 0s, outline 0s;
}
.galleryBtnActive {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 ;
    border: none;
    color: black;
    background: transparent;
    border-radius: 0;
    border-bottom: 4px solid #91caff;
    transition: border 0s, outline 0s;
}
.galleryBtnActiveItems {
    align-items: center;
    font-size: 20px;
    padding: 0px;
    border: none;
    color: black;
    background: transparent;
    border-radius: 0;
    border-bottom: 4px solid #91caff;
    transition: border 0s, outline 0s;
}
.galleryBtnItems {
    align-items: center;
    font-size: 20px;
    padding: 0px;
    border: none;
    color: black;
    background: transparent;
    border-radius: 0;
    border-bottom: 4px solid transparent;
    transition: border 0s, outline 0s;
}



.gallerySearch {
    font-size: 17px;
    width: 197px;
}

.galleryCart{
    font-size: 27px;
}

.favoriteButton {
    color: rgb(231 90 90);
    font-size: 25px;
    font-weight: bold;
    background: transparent;
    border: transparent;
}

.selectedImagesCount{
    color: rgb(231 90 90);
    font-size: 12px;
    padding: 3px;
    position: absolute;
    border-radius: 50%;
    border: 2px solid rgb(231 90 90);
    width: 30px;
    height: 30px;
    top: -15px;
    right: -30px;
    font-weight: bold;
}.favoriteCount{
    color: rgb(231 90 90);
    font-size: 12px;
    padding: 3px;
    position: absolute;
    border-radius: 50%;
    border: 2px solid rgb(231 90 90);
    width: 30px;
    height: 30px;
    top: -15px;
    right: -30px;
    font-weight: bold;
}
.privateCount{
    color: #223d8f;
    font-size: 12px;
    padding: 3px;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #223d8f;
    width: 30px;
    height: 30px;
    top: -15px;
    right: -30px;
    font-weight: bold;
}
.boxdrop{
    cursor:pointer;
    margin-right: 5px;
}
.boxdrop:hover {
    align-items: center;
    padding-bottom: 2px ;
    border-radius: 0;
    border-bottom: 4px solid #91caff;
    transition: border 0s, outline 0s;
}
.inButtonHeader{
    font-size: 25px;
    font-weight: bold;
    color:rgb(231 90 90);
    background: transparent;
    border: transparent;
    cursor: pointer;
}

.buttonIcon{
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: var(--colorBtn);
    border: 1px solid var(--colorBtn);
    color: #fff;
    margin-bottom: 5px !important;
}

.buttonIcon:hover{
    background: #fff !important;
    border: 1px solid var(--colorBtn) !important;
    color: var(--colorBtn) !important;
}
.buttonIcon svg path{

    fill: #fff !important;
}
.buttonIcon:hover svg path{
    fill: var(--colorBtn) !important;
}

.favoriteWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}


.imageContainer {
    width: 100%;
    position: relative;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
}
.selectedActions {
    position: absolute;
    top: 2%;
    left:5px;
    transform: translate(30%, 10%);
}
.selectedActionsActive {
    display: block ;
    position: absolute;
    top: 2%;
    left:5px;
    transform: translate(30%, 10%);
}
.selectedActions .ant-checkbox .ant-checkbox-inner{
    height: 20px !important;
    width: 20px !important;
}
.selectedActionsActive .ant-checkbox .ant-checkbox-inner{
    height: 20px !important;
    width: 20px !important;
}
.LeftPrivate {
    position: absolute;
    top: 2%;
    left:5px;
    transform: translate(30%, 180%);

}
.LeftPrivateActive {
    display: block ;
    position: absolute;
    top: 2%;
    left:5px;
    transform: translate(30%, 180%);
}



.favoriteActions {
    position: absolute;
    top: 3%;
    right: 0;
    transform: translate(-30%, -0%);

}

.favoriteActionsActive  {
    display: block ;
    position: absolute;
    top: 3%;
    right: 0;
    transform: translate(-35%, -0%);
}

.AddActions {
    color: #fff;
    font-size: 25px;
    position: absolute;
    top: 3%;
    right: 0;
    transform: translate(-75%, 100%);
}

.removeFolderImage  {
    display: block;
    position: absolute;
    top: 3%;
    right: 0;
    transform: translate(-33%, 191%);
}


.DownWrapper {
    position: absolute;
    left: 0;
    width: 100%;
    height: 50px;
    align-items: center;
    display: flex;
    bottom: 0;
    transform: translate(0%, -35%);

}
.favoriteActions,
.AddActions,
.selectedActions,
.DownWrapper,
.LeftPrivate,
.removeFolderImage,
.favoriteActions
{
    display: none;
}



.imageContainer:hover .favoriteActions,
.imageContainer:hover .AddActions,
.imageContainer:hover .selectedActions,
.imageContainer:hover .LeftPrivate,
.imageContainer:hover .DownWrapper,
.imageContainer:hover .removeFolderImage
{
    display: block;
}

.buttonActions{
    position: absolute;
    right: 0%;
    transform: translate(-5%, 0%);
}
.imageNameActions{
    position: absolute;
    left: 0;
    transform: translate(5%,22%);
}


.inButton{
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
    background: transparent;
    border: transparent;
    cursor: pointer;
}
.inButton:hover,
.AddActions:hover{
    opacity: 0.7;

}
.inButtonAnticon{
    font-size: 19px;
    color: #ffffff;
    opacity: 0.7;
    background: transparent;
    border: transparent;
    cursor: pointer;
}
.inButtonAnticon:hover{
    border-color: transparent !important;
    opacity: 1;
    color: #ffffff !important;
}


.imageName{
    font-size: 14px;
    width: 130px;
    text-align: start;
}


.createFolder{
    width: 50% !important;
    displey:flex;
    justify-content: center;
    align-items: center;
}
.selected{
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    background-color: #ffffff;
    padding: 10px;
    min-height: 300px;
}
.hoverEffect{
    display: block;
}

.selectedMain{
    width: 100%;
  margin-bottom: 10px;
}

.ModalImage{
    width: 1000px !important;
}



.DropDownMenu {
    display: flex;
    flex-direction: column;
}
.DropDownMenuItems {
    display: none;

}
.cartIcon{
    border:  transparent ;
    color:black;
    background: transparent;
}
.cartIconSvg{
    font-size: 30px;
}
.totalQuantity{
    width: 30px;
    height: 30px;
    top: -12px;
    right: -12px;
    position: absolute;
    border-radius: 50%;
    background: #223d8f;
    font-style: normal;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    padding: 5px;

}


/*main gallery*/
.main-gallery{

    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}
.gallery-user-name{
    padding-left: 3rem!important;
}
.gallery-user-name p{
    color: #fff;
    font-size: 45px;
    margin: 0;
    font-weight: 700;
}
.gallery-user-name span{
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}
.main-gallery-container{
    position: fixed;
    bottom: 0;
}
.open-gallery-col{
    padding-right: 3rem!important;
}
.open-gallery-col button{
    border: 2px solid #fff;
    background: transparent;
    border-radius: 0;
    padding: 10px 15px;
    white-space: nowrap;
    font-weight: 500;
    margin-top: 20px;
}
.open-gallery-col button:hover{
    background: #284292;
    border: 2px solid #284292;
}
.gallery-photos-content{
    position: relative;
    top: 70px;
}
.view-mode{
    position: relative;
    top: -3px;
    padding-right: 5px;

}
.down-icon{
    position: relative;
    top: -3px;
}

/* gallery animation */

.animate {
    animation-duration: 0.75s;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
    animation-fill-mode: backwards;
}
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0px;
    width: auto;
    margin-right: 5px;
    width: auto;
    margin-top: 80px;
}
.my-masonry-grid_column {
    padding-left: 5px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 5px;
}

.my-masonry-grid_column > div > img {
    width: 100%;
}
.masonry-image-component{
    /*filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));*/
    background: white;
}
.gallery-filter-box{
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33))
}
.checkbox-username{
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    width: -webkit-fill-available;
    position: absolute;
    z-index: 1;
    justify-content: space-between;
    display: none;
    top: -3px;
}
.gal-icons svg{
    color:#010101
}
.gallery-icon-div:hover .gal-icons svg,.gallery-icon-div:hover .gallery-icon-txt{
    color: #223D8F;
}

.img-fav-tab{
    bottom: 0px;
    text-align: center;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    position: absolute;
    z-index: 1;
    color: black;
    display: none;

}
.masonry-image-component:hover .checkbox-username, .masonry-image-component:hover .img-fav-tab{
    display: flex;
}
.checkbox-icons{
    display: flex;
    background: white;
}
.masonry-image-component .ant-checkbox-wrapper{
    padding: 5px;
}

.my-masonry-grid_column > div > img:hover{
    cursor: pointer;
}
.view-mode-div{
    height:400px;
    margin-bottom: 10px!important;
}
.view-mode-second{
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
    height: 100%;
    object-fit: cover;
}
.view-gallery-fixed{
    position: fixed;
    z-index: 10;
    top: 48px;
    right: 189px;

}
.ril__image, .ril__imagePrev, .ril__imageNext{
    height: 80%;
}


.img-fav-tab div{
    cursor: pointer;
}


.gallery-icon-txt{
    font-size: 10px;
    margin: 0;
}
.client-icon span{
    color: #223D8F;
    font-size: 18px;
}.client-icon_non{
   display: none;
}
.client-icon p{
    color: rgb(0 0 0);
}
.client-mode{
    margin: 0 15px;
}
/* Fade In */
.animate.fade {
    animation-name: animate-fade;
    animation-timing-function: ease;
}
@keyframes animate-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Pop In */
.animate.pop {
    animation-name: animate-fade;
}
@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

/* Slide In */
.animate.slide { animation-name: animate-slide; }
@keyframes animate-slide {
    0% {
        opacity: 0;
        transform: translate(0,20px);
    }
    100% {
        opacity: 1;
        transform: translate(0,0);
    }
}

/* Animation Delays */
.delay-1 {
    animation-delay: 1.5s;
}

/* Navbar */

.ant-card .ant-card-meta-title {
    color: rgb(34 61 143);

}
.ant-card .ant-card-meta-description {
    color: rgb(0 0 0);
    text-align: center;
    margin-bottom: 10px;
}
.ant-card .ant-card-meta-detail {
    overflow: hidden;
    flex: 1;
    border-bottom: 1px solid #dedede;
}
.gallery nav {
    background-color: #223D8F;
    line-height: 27px;
}
.gallery-navbar-container a{
    color: white;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid transparent;
}
.gallery-navbar-container a:hover, .gallery-navbar-container a:focus{
    border-bottom: 1px solid #fff;
    color: #fff;

}

/* gallery cards */

.gallery-user-info p{
    color: #223D8F;
    font-size: 35px;
    margin: 0;
    font-weight: 700;
}
.gallery-user-info span{
    color: #223D8F;
    font-size: 13px;
    font-weight: 500;
}
.gallery-download-all button{
    color: #223D8F;
    background: transparent;
    border: 2px solid #223D8F;
}
.gallery-download-all button:hover{
    color: #fff!important;
    background: #223D8F;
    border: 2px solid #223D8F;
}
.gallery-download-all button:hover> .gallery-download-all .font-icon{
    color: #fff!important;
}
.gallery-download-all .font-icon{
    color: #223D8F;
}
.gallery-card-container .card{
    margin: 0 10px;
    cursor: pointer;
}
.gallery-card-container .card-title{
    color: #223D8F;
    font-size: 17px;
}
.gallery-card-container .card-text{
    color: #223D8F;
    font-size: 14px
}
.download-icon{
    padding-right: 5px;
}
.studio-mode{
    color: #fff !important;
    width: 200px;
    margin: 0 15px;
    font-weight: 500;
    border:none;
    font-size: 14px!important;
}
.client-mode{
    color: #fff !important;
    width: 200px;
    margin: 0 15px;
    font-weight: 500;
    border:none;
    font-size: 14px!important;

}

.CompareText{
    text-align: center;
}


.CompareTextGo{
    cursor: pointer;
}

.colBlock{
    display: none;
}
.colNone{
    display: block
}
@media only screen and (max-width:1400px) {
    .CompareTextButtun {
        display: none !important;
    }
}
@media only screen and (max-width:992px) {
    .CompareText{
        display: none !important;
    }

    .galleryBtn {
        font-size: 23px;
    }

    .favoriteCount{
        font-size: 11px;
        width: 23px;
        height: 23px;
        top: -6px;
        right: -21px;
    }
    .privateCount{
        font-size: 11px;
        width: 23px;
        height: 23px;
        top: -6px;
        right: -21px;
    }
    .totalQuantity{
        font-size: 11px;
        width: 23px;
        height: 23px;
        top: -7px;
        right: 3px;
        padding: 3px;
    }
    .createFolder{
        width: 80% !important;
    }

}
@media only screen and (max-width:768px) {

    .open-gallery-col{
        padding-left: 3rem!important;
    }
    .gallery-user-name p{
        font-size: 28px;
    }
    .InfiniteScroll{
        margin-top: 135px !important;
    }
    .CompareTextGo{
        display: none !important;
    }
}
@media only screen and (max-width:739px){
    .gallery-photos-content {
        top: 263px;
    }
}
@media only screen and (max-width:643px) {

    .colBlock{
        display: block;
    }
    .colNone{
        display: none
    }

}@media only screen and (max-width:577px) {
    .createFolder{
        width: 100% !important;
    }
    .studioInfo{
        font-size: 12px;
        margin-bottom: 4px;
    }

    .client_icon{
        padding:  2px
    }

}
@media only screen and (max-width:438px){
    .gallery-photos-content {
        top: 296px;
    }
    .galleryBtn{
        font-size: 20px;
    }
    .favoriteButton{
        font-size: 23px;
    }
    .cartIconSvg {
        font-size: 27px;
    }
    .favoriteCount{
        font-size: 9px;
        width: 20px;
        height: 20px;
        top: -6px;
        right: -16px;
        line-height: 10px;
    }
    .privateCount{
        font-size: 9px;
        width: 20px;
        height: 20px;
        top: -6px;
        right: -16px;
        line-height: 10px;
    }
    .totalQuantity{
        font-size: 10px;
        width: 20px;
        height: 20px;
        top: -7px;
        right: 3px;
        padding: 3px;
    }
    .galleryBtnClientMode{
        font-size: 15px;
        margin-right: 10px;
    }
}