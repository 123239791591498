
/*UploadTemplate*/
.selected{
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    background-color: #ffffff;
    padding: 10px;
    min-height: 300px;
    width: 80%;
    margin: 0 auto;
}

.selectedPrice{
    text-align:center;
    width:100%;
}



.selectedOption{
    text-align:center;
}

.selectedImage_div {
    width: auto !important;
    overflow-y: hidden !important;
    height: 100%;
    max-height: 200px;
    padding: 5px;
    opacity: 0.5;
    border-radius: 7px;
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
}


.selectedImage_div .ant-spin-nested-loading {
    display: none !important;
}

.selectedImage_divHover {
        width: auto !important;
        overflow-y: hidden !important;
        height: 100%;
        max-height: 200px;
        padding: 5px;
        opacity:1;
        border-radius: 7px;
        filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
        border: solid 2px #223d8f;
}
.imageContext{
    height: 100%;
    max-height: 130px;
    padding: 10px;

}
.selectedImageSizeName{
    height: 100%;
    max-height: 30px;
    text-align: center;
}
.selectedImage_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 3px;
}


.selectedImage_div:hover {
    cursor: pointer;
    opacity: 1;
    border: solid 2px #223d8f;
}
