 .flexCenterClassAdd{
  display: block !important;

}

 .ref4class{
   position: absolute;
   right: 10px;
   bottom: 120px;
   width: 400px;
   height: 80px;
   z-index: -1;
 }
.main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
}

.forBg{

}