
.left-sidebar {
    min-height: 100vh;
    background-color: #223d8f;
    padding: 0 !important;
}
.login-root{
    overflow-x: hidden;
}

.login-moto {
    font-size: 1.7rem;
    color: #ffffff;
    background-color: #284292;
    padding: 2rem 2rem;
    position: absolute;
    top: 50%;
    left: 4.3em;
    transform: translate(-50%, -50%);
}
.login-bg{
    background: none!important;
}
.circle-div {
    position: absolute;
    top: 50%;
    left: 34%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.circle-div-sm{
    position: absolute;
    left: 2rem;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.blue-circle {
    position: relative;
    right: -1.5rem;
    top: 1.5rem;
}

.blue-circle img, .blue-circle-sm img {
    width: 87%;
    position: absolute;
    top: 25%;
}

.main-circle {
    width: 200px;
    height: 200px;
}
.main-circle-sm{
    width: 50px;
    height: 50px;
}

.right-sidebar {
    margin: auto;
}

.tricolor-logo {
    width: 30%;
    margin-top: 3rem!important;
}

.right-sidebar div {
    font-size: 15px;
    color: #393939;
    font-weight: 400;
    width: auto;
}

.remember-checkbox {
    padding-left: 0.5rem;
    position: relative;
    top: -0.5rem;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    cursor: pointer;
}

.login-checkbox {
    white-space: nowrap;
}

.invalid-feedback {
    text-align: start;
    color: #DC3545 !important;
    font-size: 14px!important;
    text-indent: 1.3rem;

}

.checkbox {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 0.5px solid #393939;
}

.login-form {
    min-width: 15rem;
    min-height: 140px;
    border-radius: 10px;
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    background-color: #ffffff;
}
.send-email-btn{
    width: 100px;
    margin-top: 1rem;
}

.forgot-pass {
    font-size: 14px;
    line-height: 30px;
    color: #223d8f;
    font-weight: 400;
    position: relative;
    top: -0.2rem;
    background: none!important;
    border: none!important;
    color: #223D8F!important;
}
.forgot-pass-title{
    /*font-size: 12px!important;*/
    width: 100%;
    font-size: 16px!important;
    text-align: center;
}
.forget-pass-border{
    display: block;
    width: -webkit-fill-available;
    border: none;
    border-bottom: 1px solid #393939;

    margin: 15px 70px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.forget-password-modal button{
    font-size: 12px;
}
.enter-email-address{
    font-size: 21px;
    text-align: center;
}
.user-icon {
    color: #9f9f9f;
    font-size: 24px;
}

.input-username {
    width: 95% !important;
    font-size: 19px !important;
    color: black !important;
    padding-left: 2rem !important;
    height: 40px;
    text-align: start;
    margin-right: -2rem;
}

.input-username:focus {
    box-shadow: none !important
}

.login-input {
    padding: 1rem 1rem 0 1rem;
}
.login-btn-navigate:hover{
    color: #223D8F!important;
}
.password-input {
    padding: 0 1rem;
}

.input-user {
    border-bottom: 1px solid #9F9F9F;
}
.btnDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.create-btn {
    color: #223D8F !important;
    background: #fff !important;
    width: 200px;
    font-size: 14px!important;
}

.create-btn:hover {
    background: #223D8F !important;
    color: #fff !important;
}

.login-btn {
    /*width: -webkit-fill-available;*/
    color: #fff !important;
    background: #223D8F !important;
    width: 200px;
    font-size: 14px!important;
}
.login-btn:hover{
    color: #223D8F !important;
    background: #fff !important;
}
.login-btn:hover .login-btn-navigate{
    color: #223D8F !important;
}
.footer-logo {
    width: 5rem;
    text-align: center;
}

.copyright {
    margin-top: 8rem;
}

.copyright span {
    font-size: 16px;
    color: #9f9f9f;
}

.copy {
    width: 17px;
    height: 17px;
}
.create-btn-sm{
    margin-top: 3rem!important;
}
.forgot-password {
    text-align: end;
}

.login-icon{
    width: 1.7rem;
}

.login-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.create-account{
    position: relative;
    top: 5px;
}
.login-media{
    margin-top:3rem!important
}
.imaging-center-div{
    background-color: #223d8f;
}
.imaging-center-sm{
    font-size: 12px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    /*margin-right: -12px;*/
    height: 40px;
    padding-top: 10px;
}
.incorrect-data{
    color: #fc0000;
    font-size: 13px;
    display: none;
}
.login-icons{
    width: 1.7rem;
}
.checkbox-remember-me span{
    background: white;
    border: none;
    cursor:pointer
}
.remember-me-input{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.checkbox-remember-me span input{
    cursor:pointer
}
.remember-me-container{
    padding-left: 4px!important;
}
.remember-me-container label{
    margin-left: 10px;
    cursor: pointer
}
.error-message{
    color: #dc3545;
    text-align: center;
}
.success-message{
    text-align: center;
    color: #007F3E;
}
.pass-required{
    padding-bottom: 13px;
}
.tricolor-login-container{
    margin-top: 3rem;
}
.password-toggle{
    font-size: 22px;
}.password-toggle svg {
     fill: #8a8585 !important;
}

/* media */
@media only screen and (min-width:1700px){
    .tricolor-login-container {
        margin-top: 10rem!important;
    }
}
@media only screen and (max-width:1224px){
    .btn-media{
        font-size: 12px;
        line-height: normal;
    }
    .signUp-details, .signUp-details-right {
        max-width: 296px;
        min-height: 365px;
    }
}
@media only screen and (max-width:991px){
    .input-username{
        font-size: 14px !important;
    }
    .login-icon {
        width: 1.3rem;
    }
    .login-media{
        margin-top: 1rem!important
    }
    .main-circle {
        width: 150px;
        height: 150px;
    }
    .blue-circle {
        width: 100px;
        height: 100px;
    }
    .circle-div{
        left: 43%;
    }
}
@media only screen and (max-width:919px) {
    .forgot-password {
        text-align: start !important;
    }

    .login-btn-div,
    .create-btn-div {
        padding: 0 !important;
    }

    .tricolor-logo {
        width: 77%;
        margin-top: 2rem!important;
    }
    .login-moto {
        font-size: 1.3rem;
    }
    .copyright {
        margin-top: 0rem;
    }
    .create-btn-sm{
        margin-top: 1rem!important;
    }
}
@media only screen and (max-width:769px) {
    .forgot-pass{
        font-size: 13px!important;
    }
    .login-form{
        min-height: 115px;
    }
    .login-hr{
        margin: 10px 0px
    }
}
@media only screen and (max-width:767px) {
    .circle-div {
        top: 100% !important;
        left: 50% !important;
    }
    .main-circle-sm{
        margin-top:1rem
    }
}
@media only screen and (max-width:425px) {
    .input-username {
        padding-left: 0.5rem !important;
    }
    .copyright span {
        font-size: 12px;
    }

}