.background__button-save {
    float: right;
}
.background_Table .ant-table-cell {
    padding: 3px 16px !important;
}

.background_Table .ant-table-cell {
    padding: 3px 16px !important;

}