.spacePdf{
    line-height: 5px;
    color: #000;
    filter: drop-shadow(0 0 11px rgba(159, 159, 159, 0.33));
    background-color: #ffffff;
    padding: 10px;
    text-align: center;
}

.pdfContent{
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    box-shadow: 0 0 11px rgba(159, 159, 159, 0.33);
    padding: 10px 40px;
    background-color: #fff !important;
}

.pdfContent h2 {
    font-size: 20px;
    color: #9b9b9b;
}



.Row{
    /*border: 3px solid #eaeaea;*/
    padding: 10px 20px;
    margin-bottom: 5px;
}

.infoStudio{
    width: 150px;
    position: relative;
}

.infoStudioImg{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.infoStudioText{
    width: 100%;
    font-size: 16px;
    line-height: 14px;
    font-family: 'Bahnschrift', sans-serif;
    color: #000000;
    margin-top: 16px;
    text-align: center;
    padding-bottom: 6px;
}

.textSpace{
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: end;

}


.pdfList{
    display: flex;
    justify-content:  end;
    width: 100%;
    margin-left: 0;
}

.itemStrong{
    width: 100%;
    line-height: 1;
    justify-content:  end;
    color: #9f9f9f;
}

.itemD{
    text-align: end;
    line-height: 1;
    font-size: 14px;
    width:100%;
    border-radius: unset;
    color: red;
}

.itemG{
    text-align: end;
    line-height: 1;
    font-size: 14px;
    width:100%;
    border-radius: unset;
    color: green;
}

.itemP{
    text-align: end;
    line-height: 1;
    font-size: 14px;
    width:100%;
    border-radius: unset;
    color: black;
    font-weight: bold;
}

.clientsContainer{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    /*border: 3px solid #eaeaea;*/
    padding: 20px;
    border-radius: 12px;
}

.clients{
    width: 100%;
    margin-bottom: 15px;
    text-align: start;
}

.clients .ant-space-item{
    width: 100% !important;
}

.clientsTextName{
    color: #62676e;
    font-weight: 600;
    font-size: 15px;
    line-height: 2;
}

.clientsText{
    width: 100%;
    font-size: 15px;
    line-height: 1;
}

.texNotes{
    font-size: 20px;
}


.pdfTable{
    width:100% !important;
    margin: 0 auto;
    min-height: 200px;
}

.pNotes{
    margin-top: 15px;
    font-size: 17px;
    padding: 5px 20px;
    line-height: 1;
    width: 100%;
    text-align: start;
}

.PaymentTitle{
    display: flex;
    justify-content: center !important;
    margin-top: 20px;
}

.PaymentBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment{
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 0 20px;
    margin-bottom: 10px;
}

.paymentDep{
    width:85px !important;
}

.paymentItem{
    margin: 0 30px;
}

.pdfBalance{
    display: flex;
    justify-content:  start;
    width: 100%;
    margin-left: 0;
}

.itemBalance{
    margin: 10px 0;
    font-size: 25px;
    width:27%;
}

.Balance{
    width:37%;
    margin-top: 10px;
    text-align: end;
}

.PaymentBodyPackages{
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.pdfPackage {
    display: flex;
    justify-content:  end;
    width: 100%;
    margin-left: 0;
}

.pdfLina{
    margin-top: 20px;
    padding: 2px 0;
    border-top: 1px solid #000;
    border-bottom: 2px solid #000;
}

.pdfLinaDown{
    font-size: 15px;
    line-height: 1;
    text-align: start;
    padding-top:10px;
    margin-bottom: 40px;
}

.pdfListSignature{
    display: flex;
    justify-content:  space-between;
    width: 100%;
    margin-left: 0;
    height: 15px;
}

.itemStrongSignature{
    width: 100%;
    color: #9f9f9f;
}

.itemSignature{
    width: 50%;
    padding-bottom: 13px;
    border: none;
    border-bottom: 1px solid #62676e;
}

.pdfTrems{
    line-height: 1.3;
    text-align: start;
}

.contractBtn{
    background: #327dc3;
    color: #fff;
    margin: 20px 0;
}