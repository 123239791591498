.ql-picker-label{
    line-height: 0px;
}
.ql-snow .ql-picker-label::before{
    line-height: 0px;

}
.ql-snow .ql-picker{
    height: 0px;
    float: none;
    display: inline-flex;
    vertical-align: unset;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    margin-top: 25px;
}
.ql-picker-options{
    line-height: 20px;
}

.ql-toolbar.ql-snow .ql-picker-label {
    border: none;
}
.ql-toolbar{
    line-height: 100px;
}

.ql-container {
    min-height: 200px;
}
.quill {
    background-color: white;
}
.contractBtn{
    background: #327dc3;
    color: #fff;
    margin: 20px 0;
}
